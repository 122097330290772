// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/header/SearchBar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/header/SearchBar.tsx");
  import.meta.hot.lastModified = "1706771110297.2913";
}
// REMIX HMR END

import { Form } from '@remix-run/react';
export function SearchBar() {
  let initialQuery = '';
  if (typeof window === 'undefined') {
    // running in a server environment
  } else {
    // running in a browser environment
    initialQuery = new URL(window.location.href).searchParams.get('q') ?? '';
  }
  return <Form method="get" action="/search" key={initialQuery}>
      <input type="search" name="q" defaultValue={initialQuery} placeholder="Search"
    // className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
    style={{
      color: '#fff',
      background: 'none',
      border: '1px solid #ccc',
      borderRadius: '30px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      width: '350px',
      height: '35px',
      marginRight: '10px'
    }} />
    </Form>;
}
_c = SearchBar;
var _c;
$RefreshReg$(_c, "SearchBar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;